const spanishLessonsGroup1: Array<object> = [
  {
    lessonId: "es1.1",
    groupName: "Spanish 1",
    groupIndex: 0,
    lessonName: "Intro to Spanish 1",
    lessonIndex: 0,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
  {
    lessonId: "es1.2",
    groupName: "Spanish 1",
    groupIndex: 0,
    lessonName: "Intro to Spanish 2",
    lessonIndex: 1,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Tu nombre ____ Lance?",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Por qué",
        questionAnswer: "why",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Why",
        questionOptions: ["Sí", "Hola", "Por qué", "Gracias"],
        questionAnswer: "por que",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, gracias ____ venir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this word.",
        questionContext: "Porque",
        questionAnswer: "because",
      },
      {
        questionNumber: 6,
        questionType: "Translate these two words.",
        questionContext: "Porque Por qué",
        questionOptions: [
          "Because Why",
          "Why Why",
          "Because Because",
          "Why Because",
        ],
        questionAnswer: "because why",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
  {
    lessonId: "es1.3",
    groupName: "Spanish 1",
    groupIndex: 0,
    lessonName: "Intro to Spanish 3",
    lessonIndex: 2,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Pedro es mi herman_",
        questionAnswer: "o",
      },
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Maria es mi herman_",
        questionAnswer: "a",
      },
      {
        questionNumber: 3,
        questionType: "Fill in the blank.",
        questionContext: "Él es mi ____",
        questionOptions: ["Hermano", "Hermana", "Madre", "Prima"],
        questionAnswer: "hermano",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Este es nuestro herman_",
        questionAnswer: "o",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
  {
    lessonId: "es1.4",
    groupName: "Spanish 1",
    groupIndex: 0,
    lessonName: "Intro to Spanish 4",
    lessonIndex: 3,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
  {
    lessonId: "es1.5",
    groupName: "Spanish 1",
    groupIndex: 0,
    lessonName: "Intro to Spanish 5",
    lessonIndex: 4,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
];
const spanishLessonsGroup2: Array<object> = [
  {
    lessonId: "es2.1",
    groupName: "Spanish 2",
    groupIndex: 1,
    lessonName: "Medium Spanish 1",
    lessonIndex: 0,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
  {
    lessonId: "es2.2",
    groupName: "Spanish 2",
    groupIndex: 1,
    lessonName: "Medium Spanish 2",
    lessonIndex: 1,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
  {
    lessonId: "es2.3",
    groupName: "Spanish 2",
    groupIndex: 1,
    lessonName: "Medium Spanish 3",
    lessonIndex: 2,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
  {
    lessonId: "es2.4",
    groupName: "Spanish 2",
    groupIndex: 1,
    lessonName: "Medium Spanish 4",
    lessonIndex: 3,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
  {
    lessonId: "es2.5",
    groupName: "Spanish 2",
    groupIndex: 1,
    lessonName: "Medium Spanish 5",
    lessonIndex: 4,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
];
const spanishLessonsGroup3: Array<object> = [
  {
    lessonId: "es3.1",
    groupName: "Spanish 3",
    groupIndex: 2,
    lessonName: "Pro Spanish 1",
    lessonIndex: 0,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
  {
    lessonId: "es3.2",
    groupName: "Spanish 3",
    groupIndex: 2,
    lessonName: "Pro Spanish 2",
    lessonIndex: 1,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
  {
    lessonId: "es3.3",
    groupName: "Spanish 3",
    groupIndex: 2,
    lessonName: "Pro Spanish 3",
    lessonIndex: 2,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
  {
    lessonId: "es3.4",
    groupName: "Spanish 3",
    groupIndex: 2,
    lessonName: "Pro Spanish 4",
    lessonIndex: 3,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
  {
    lessonId: "es3.5",
    groupName: "Spanish 3",
    groupIndex: 2,
    lessonName: "Pro Spanish 5",
    lessonIndex: 4,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Mi nombre ____ Lance",
        questionAnswer: "es",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Hola",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["Sí", "Hola", "Feliz", "Gracias"],
        questionAnswer: "hola",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ____ por venir",
        questionAnswer: "gracias",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Mi nombre ____ Lance",
        questionOptions: ["Sí", "Hola", "Es", "Gracias"],
        questionAnswer: "es",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Hola, ¿____ estás?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sí", "hola", "es", "gracias"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, ¿como estás?",
        questionAnswer: "hola",
      },
    ],
  },
];

const SpanishLessons: object[][] = [
  spanishLessonsGroup1,
  spanishLessonsGroup2,
  spanishLessonsGroup3,
];

export default SpanishLessons;
