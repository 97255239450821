const letsLearnPhraseOptions: Array<unknown> = [
  `Hi, I'm `,
  "Salut, J'Suis ",
  "Mbote, Kombo Na Ngai ",
  "Hola, Mi Nombre Es ",
  "Malo igama lam ngu ",
  "Hallo, Ich Bin ",
  "Oi, Meu Nome é ",
  "Ciao, Mi Chiamo ",
  "Sanibonani igama lami ngingu ",
];
export default letsLearnPhraseOptions;
