const enlgishFromSpanishLessonsGroup1: Array<object> = [
  {
    lessonId: "en1.1",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 1",
    lessonIndex: 0,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
  {
    lessonId: "en1.2",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 2",
    lessonIndex: 1,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
  {
    lessonId: "en1.3",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 3",
    lessonIndex: 2,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
  {
    lessonId: "en1.4",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 4",
    lessonIndex: 3,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
  {
    lessonId: "en1.5",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 5",
    lessonIndex: 4,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
];
const enlgishFromSpanishLessonsGroup2: Array<object> = [
  {
    lessonId: "en1.1",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 1",
    lessonIndex: 0,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
  {
    lessonId: "en1.2",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 2",
    lessonIndex: 1,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
  {
    lessonId: "en1.3",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 3",
    lessonIndex: 2,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
  {
    lessonId: "en1.4",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 4",
    lessonIndex: 3,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
  {
    lessonId: "en1.5",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 5",
    lessonIndex: 4,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
];
const enlgishFromSpanishLessonsGroup3: Array<object> = [
  {
    lessonId: "en1.1",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 1",
    lessonIndex: 0,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
  {
    lessonId: "en1.2",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 2",
    lessonIndex: 1,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
  {
    lessonId: "en1.3",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 3",
    lessonIndex: 2,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
  {
    lessonId: "en1.4",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 4",
    lessonIndex: 3,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
  {
    lessonId: "en1.5",
    groupName: "English 1",
    groupIndex: 0,
    lessonName: "Intro to English 5",
    lessonIndex: 4,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionAnswer: "is",
      },
      {
        questionNumber: 2,
        questionType: "Traduzca la palabra.",
        questionContext: "Hello",
        questionAnswer: "hola",
      },
      {
        questionNumber: 3,
        questionType: "Escoja la traducción.",
        questionContext: "Hola",
        questionOptions: ["Yes", "Hello", "Happy", "Thank you"],
        questionAnswer: "hello",
      },
      {
        questionNumber: 4,
        questionType: "Complete a frase.",
        questionContext: "Hello, thank you ____ coming",
        questionAnswer: "for",
      },
      {
        questionNumber: 5,
        questionType: "Traduzca la frase.",
        questionContext: "Mi nombre es Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Complete la frase.",
        questionContext: "My name ____ Lance",
        questionOptions: ["Is", "Hello", "Yes", "Thank you"],
        questionAnswer: "is",
      },
      {
        questionNumber: 7,
        questionType: "Complete la frase.",
        questionContext: "Hello, how ____ you?",
        questionAnswer: "are",
      },
      {
        questionNumber: 8,
        questionType: "Traduzca la palabra.",
        questionContext: "How",
        questionAnswer: "como",
      },
      {
        questionNumber: 9,
        questionType: "Escoja la traducción.",
        questionContext: "Thank you",
        questionOptions: ["Hola", "Como", "Gracias", "De nada"],
        questionAnswer: "gracias",
      },
      {
        questionNumber: 10,
        questionType: "Complete la frase.",
        questionContext: "Hello, _____ is your name?",
        questionAnswer: "what",
      },
    ],
  },
];

const EnglishFromSpanishLessons: object[][] = [
  enlgishFromSpanishLessonsGroup1,
  enlgishFromSpanishLessonsGroup2,
  enlgishFromSpanishLessonsGroup3,
];

export default EnglishFromSpanishLessons;
