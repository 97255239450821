const frenchLessonsGroup1: Array<object> = [
  {
    lessonId: "fr1.1",
    groupName: "French 1",
    groupIndex: 0,
    lessonName: "Intro to French 1",
    lessonIndex: 0,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
  {
    lessonId: "fr1.2",
    groupName: "French 1",
    groupIndex: 0,
    lessonName: "Intro to French 2",
    lessonIndex: 1,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
  {
    lessonId: "fr1.3",
    groupName: "French 1",
    groupIndex: 0,
    lessonName: "Intro to French 3",
    lessonIndex: 2,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
  {
    lessonId: "fr1.4",
    groupName: "French 1",
    groupIndex: 0,
    lessonName: "Intro to French 4",
    lessonIndex: 3,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
  {
    lessonId: "fr1.5",
    groupName: "French 1",
    groupIndex: 0,
    lessonName: "Intro to French 5",
    lessonIndex: 4,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
];
const frenchLessonsGroup2: Array<object> = [
  {
    lessonId: "fr2.1",
    groupName: "French 2",
    groupIndex: 1,
    lessonName: "Medium French 1",
    lessonIndex: 0,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
  {
    lessonId: "fr2.2",
    groupName: "French 2",
    groupIndex: 1,
    lessonName: "Medium French 2",
    lessonIndex: 1,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
  {
    lessonId: "fr2.3",
    groupName: "French 2",
    groupIndex: 1,
    lessonName: "Medium French 3",
    lessonIndex: 2,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
  {
    lessonId: "fr2.4",
    groupName: "French 2",
    groupIndex: 1,
    lessonName: "Medium French 4",
    lessonIndex: 3,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
  {
    lessonId: "fr2.5",
    groupName: "French 2",
    groupIndex: 1,
    lessonName: "Medium French 5",
    lessonIndex: 4,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
];
const frenchLessonsGroup3: Array<object> = [
  {
    lessonId: "fr3.1",
    groupName: "French 3",
    groupIndex: 2,
    lessonName: "Pro French 1",
    lessonIndex: 0,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
  {
    lessonId: "fr3.2",
    groupName: "French 3",
    groupIndex: 2,
    lessonName: "Pro French 2",
    lessonIndex: 1,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
  {
    lessonId: "fr3.3",
    groupName: "French 3",
    groupIndex: 2,
    lessonName: "Pro French 3",
    lessonIndex: 2,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
  {
    lessonId: "fr3.4",
    groupName: "French 3",
    groupIndex: 2,
    lessonName: "Pro French 4",
    lessonIndex: 3,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
  {
    lessonId: "fr3.5",
    groupName: "French 3",
    groupIndex: 2,
    lessonName: "Pro French 5",
    lessonIndex: 4,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Je m'___ Lance",
        questionAnswer: "appelle",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Bonjour",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Hello",
        questionOptions: ["oui", "bonjour", "content", "merci"],
        questionAnswer: "bonjour",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "bonjour, ____ pour venir",
        questionAnswer: "merci",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Je m'appelle Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Je m'____ Lance",
        questionOptions: ["appelle", "bonjour", "oui", "merci"],
        questionAnswer: "appelle",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça va ____?",
        questionAnswer: "bien",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "how",
        questionAnswer: "comment",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["oui", "bonjour", "merci", "salut"],
        questionAnswer: "merci",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "Bonjour, ça ____ bien?",
        questionAnswer: "va",
      },
    ],
  },
];

const FrenchLesson: object[][] = [
  frenchLessonsGroup1,
  frenchLessonsGroup2,
  frenchLessonsGroup3,
];

export default FrenchLesson;
