const portugueseLessonsGroup1: Array<object> = [
  {
    lessonId: "pt1.1",
    groupName: "Portuguese 1",
    groupIndex: 0,
    lessonName: "Intro to Portuguese 1",
    lessonIndex: 0,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
  {
    lessonId: "pt1.2",
    groupName: "Portuguese 1",
    groupIndex: 0,
    lessonName: "Intro to Portuguese 2",
    lessonIndex: 1,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
  {
    lessonId: "pt1.3",
    groupName: "Portuguese 1",
    groupIndex: 0,
    lessonName: "Intro to Portuguese 3",
    lessonIndex: 2,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
  {
    lessonId: "pt1.4",
    groupName: "Portuguese 1",
    groupIndex: 0,
    lessonName: "Intro to Portuguese 4",
    lessonIndex: 3,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
  {
    lessonId: "pt1.5",
    groupName: "Portuguese 1",
    groupIndex: 0,
    lessonName: "Intro to Portuguese 5",
    lessonIndex: 4,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
];
const portugueseLessonsGroup2: Array<object> = [
  {
    lessonId: "pt2.1",
    groupName: "Portuguese 2",
    groupIndex: 1,
    lessonName: "Medium Portuguese 1",
    lessonIndex: 0,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
  {
    lessonId: "pt2.2",
    groupName: "Portuguese 2",
    groupIndex: 1,
    lessonName: "Medium Portuguese 2",
    lessonIndex: 1,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
  {
    lessonId: "pt2.3",
    groupName: "Portuguese 2",
    groupIndex: 1,
    lessonName: "Medium Portuguese 3",
    lessonIndex: 2,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
  {
    lessonId: "pt2.4",
    groupName: "Portuguese 2",
    groupIndex: 1,
    lessonName: "Medium Portuguese 4",
    lessonIndex: 3,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
  {
    lessonId: "pt2.5",
    groupName: "Portuguese 2",
    groupIndex: 1,
    lessonName: "Medium Portuguese 5",
    lessonIndex: 4,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
];
const portugueseLessonsGroup3: Array<object> = [
  {
    lessonId: "pt3.1",
    groupName: "Portuguese 3",
    groupIndex: 2,
    lessonName: "Pro Portuguese 1",
    lessonIndex: 0,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
  {
    lessonId: "pt3.2",
    groupName: "Portuguese 3",
    groupIndex: 2,
    lessonName: "Pro Portuguese 2",
    lessonIndex: 1,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
  {
    lessonId: "pt3.3",
    groupName: "Portuguese 3",
    groupIndex: 2,
    lessonName: "Pro Portuguese 3",
    lessonIndex: 2,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
  {
    lessonId: "pt3.4",
    groupName: "Portuguese 3",
    groupIndex: 2,
    lessonName: "Pro Portuguese 4",
    lessonIndex: 3,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
  {
    lessonId: "pt3.5",
    groupName: "Portuguese 3",
    groupIndex: 2,
    lessonName: "Pro Portuguese 5",
    lessonIndex: 4,
    lessonQuestions: [
      {
        questionNumber: 1,
        questionType: "Fill in the blank.",
        questionContext: "Meu nome ____ Lance",
        questionAnswer: "e",
      },
      {
        questionNumber: 2,
        questionType: "Translate this word.",
        questionContext: "Olá",
        questionAnswer: "hello",
      },
      {
        questionNumber: 3,
        questionType: "Find the correct translation.",
        questionContext: "Yes",
        questionOptions: ["Sim", "Olá", "Feliz", "Obrigado"],
        questionAnswer: "sim",
      },
      {
        questionNumber: 4,
        questionType: "Fill in the blank.",
        questionContext: "olá, obrigado ____ vir",
        questionAnswer: "por",
      },
      {
        questionNumber: 5,
        questionType: "Translate this phrase.",
        questionContext: "Meu nome é Lance",
        questionAnswer: "my name is Lance",
      },
      {
        questionNumber: 6,
        questionType: "Find the missing word.",
        questionContext: "Meu nome ____ Lance",
        questionOptions: ["Sim", "Olá", "É", "Obrigado"],
        questionAnswer: "e",
      },
      {
        questionNumber: 7,
        questionType: "Fill in the blank.",
        questionContext: "Olá, ____ você está?",
        questionAnswer: "como",
      },
      {
        questionNumber: 8,
        questionType: "Translate this word.",
        questionContext: "como",
        questionAnswer: "how",
      },
      {
        questionNumber: 9,
        questionType: "Find the correct translation.",
        questionContext: "Thank you",
        questionOptions: ["sim", "olá", "é", "obrigado"],
        questionAnswer: "obrigado",
      },
      {
        questionNumber: 10,
        questionType: "Fill in the blank.",
        questionContext: "____, como você está?",
        questionAnswer: "ola",
      },
    ],
  },
];

const PortugueseLessons: object[][] = [
  portugueseLessonsGroup1,
  portugueseLessonsGroup2,
  portugueseLessonsGroup3,
];

export default PortugueseLessons;
